import React from "react"
import { StaticImage as Img } from "gatsby-plugin-image"
import HeroImageGroup from "./style"
export default function ImageGroup() {
  return (
    <>
      <HeroImageGroup>
        <HeroImageGroup.Inner>
          <Img
            src="../../../../../assets/image/marketing/hero-img.png"
            className=" welcome-image--group-01__main"
            placeholder="blurred"
            height={750}
            alt="hero image main"
          />
        </HeroImageGroup.Inner>
      </HeroImageGroup>
    </>
  )
}
