import React from "react"
import { PageWrapper } from "~components/Core"
import HeroSection from "~sections/marketing/Hero"
import ServiceSection from "~sections/marketing/Service"
import ContentSectionOne from "~sections/marketing/ContentOne"
import ContentSectionTwo from "~sections/marketing/ContentTwo"
import FooterSection from "~sections/utility/Footer"

export default function Marketing() {
  return (
    <PageWrapper innerPage={true}>
      <HeroSection />
      <ServiceSection />
      <ContentSectionOne />
      <ContentSectionTwo />
      <FooterSection />
    </PageWrapper>
  )
}
