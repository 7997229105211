import styled from "styled-components/macro"
import { Box, Heading, Paragraph } from "~styled"

const Hero = styled(Box)`
  padding-top: 180px;
  padding-bottom: 55px;
  @media (min-width: 575px) {
    padding-top: 273px;
  }
  @media (min-width: 768px) {
    padding-top: 270px;
    padding-bottom: 60px;
  }
  @media (min-width: 992px) {
    padding-top: 210px;
    padding-bottom: 130px;
  }
  @media (min-width: 1200px) {
    padding-top: 140px;
    padding-bottom: 100px;
  }
  @media (min-width: 1366px) {
    padding-top: 216px;
    padding-bottom: 187px;
  }
  /* .newsletter-form {
        max-width: 521px;
    } */
`
Hero.Title = styled(Heading)`
  font-size: 50px;
  font-weight: 500;
  letter-spacing: -1px;
  line-height: 1.075;
  margin-bottom: 35px;

  @media (min-width: 480px) {
    font-size: 60px;
  }

  @media (min-width: 768px) {
    font-size: 70px;
  }

  @media (min-width: 992px) {
    font-size: 80px;
  }
  .typed-cursor {
    display: none !important;
  }
`
Hero.Text = styled(Paragraph)`
  color: rgba(38, 39, 41, 0.7);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 34px;
  margin-bottom: 53px;
  @media (min-width: 992px) {
    font-size: 21px;
  }
  @media (min-width: 1200px) {
    // padding-right: 150px;
  }
`
Hero.Content = styled(Box)`
  margin-top: 80px;
  @media (min-width: 992px) {
    margin-top: 0;
  }
`

Hero.Image = styled.img`
  height: 60px;
  margin: 8px;
`
export default Hero
