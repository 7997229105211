import Images from "~data/imageImports"
const serviceData = {
  services: [
    {
      id: "ms1",
      icon: "fas fa-book-reader fa-3x",
      iconColor: "#ffd166",
      title: "Daily Personalized Topics",
      text:
        "Dive into new topics every day, crafted to inspire and challenge you on your quest for financial freedom, wellness, and more.",
    },
    {
      id: "ms2",
      icon: "fas fa-check-double fa-3x",
      iconColor: "#96f7d2",
      title: "Adaptive Check-ins",
      text:
        "Progress with smart check-ins that adapt and personalize to keep you aligned with your goals, ensuring a mindful and accountable growth journey.",
    },
    {
      id: "ms3",
      icon: "fas fa-book-open fa-3x",
      iconColor: "#a9d2ff",
      title: "Curated Resources",
      text:
        "Deepen your understanding with handpicked books, articles, and more, selected to complement your daily topics and accelerate your learning journey.",
    },
  ],
}
export default serviceData
