import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import ImageGroup from "./Components/ImageGroup"
import Content from "./style"
export default function ContentSectionOne({ ...rest }) {
  return (
    <>
      <Content backgroundColor="#f8f9fc" {...rest}>
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col xs="10" className="col-xl-5 col-lg-5 col-md-6 col-xs-8">
              <ImageGroup mb="60px" mbLG="0" />
            </Col>
            {/* Content Widgets */}
            <Col
              xs="auto"
              className="col-xxl-4 col-xl-5 offset-xl-1 col-lg-6 offset-lg-1 col-md-8 col-xs-9"
            >
              <Content.Title as="h2">Build Habits That Last</Content.Title>
              <Content.Text>
                Set goals, track progress, and cultivate habits that align with
                your vision of success. Seamless, intuitive, and empowering, the
                Habit Tracker transforms aspirations into daily routines,
                setting the stage for lifelong achievements.
              </Content.Text>
            </Col>
          </Row>
        </Container>
      </Content>
    </>
  )
}
