import React from "react"
import { StaticImage as Img } from "gatsby-plugin-image"
import Images from "./style"
export default function ImageGroup({ ...rest }) {
  return (
    <Images {...rest}>
      <Images.Main>
        <Img
          alt="content image"
          src="../../../../../assets/image/marketing/contentTwo.png"
          height={600}
          placeholder="blurred"
        />
      </Images.Main>
      <Images.One>
        <Img
          layout="fullWidth"
          src="../../../../../assets/image/marketing/l1-contentTwo-shape-1.png"
          alt="content two"
        />
      </Images.One>
      <Images.Two>
        <Img
          layout="fullWidth"
          src="../../../../../assets/image/marketing/gray-dots.png"
          alt="content two"
        />
      </Images.Two>
    </Images>
  )
}
